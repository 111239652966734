<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <ul>
          <li>
            <a href="https://www.boxiego.com/#contact-us" target="_blank">
              Contact Us
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright pull-right">
        &copy; 2022 <a href="https://www.boxiego.com" rel="noopener" target="_blank">BoxieGo</a>.</span>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {}

</script>
<style>

</style>
