<template>
  <div class="login-page" v-loading="isLoading">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="login">
                <card type="login">
                  <img slot="header" src="/static/img/boxiego-logo.png">

                  <fg-input v-model="form.email" addon-left-icon="nc-icon nc-single-02"
                            placeholder="Email"></fg-input>

                  <fg-input v-model="form.password" addon-left-icon="nc-icon nc-key-25" placeholder="Password"
                            type="password"></fg-input>

                  <br>

                  <p-button native-type="submit" slot="footer" type="warning" round block class="mb-3">Login</p-button>
                </card>
              </form>
            </div>
          </div>
        </div>
        <app-footer v-if="false"></app-footer>
        <div class="full-page-background" style="background-image: url(static/img/warehouse-1.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Checkbox, Button } from 'src/components/UIComponents';
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button
    },
    mixins: [NotifyMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    methods: {
      ...mapActions({
        signIn: 'auth/signIn',
        signOutAction: 'auth/signOut'
      }),
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      async login() {
        this.isLoading = true
        try {
          await this.signOutAction()
          // if (this.user !== null) await this.signOutAction()
          await this.signIn(this.form)
          this.isLoading = false
          if (this.user.account.account_type === 'provider') this.$router.replace({ name: 'Warehouse Shipments' })
          else this.$router.replace({ name: 'Client Shipments' })
        } catch (e) {
          // presume e.response.status = 422
          let msg = 'Sorry, your login credentials could not be verified.'
          this.showNotify(msg, 'danger', 'Failed')
          this.isLoading = false
        }
      },
    },
    data() {
      return {
        isLoading: false,
        form: {
          email: '',
          password: ''
        }
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
